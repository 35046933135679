import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";

export const Social = ({ currentDashboardGraph, indexDashboard, idDashboard, isSmallScreen }) => {

    return ( 
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

        </Grid>
    )
}