import React from 'react';
import ReactECharts from 'echarts-for-react';
import { formatDataLabel, formatNumber, formatNumLabel, getColorBasedOnIndex } from '../../utils/graphUtils.tsx';

const MixedChartComponent = ({ data, color = 'red' }) => {

    const maxY1Value = (data.y1value) ? Math.max(...data.y1value): 0;
    data.xvalue = data.xvalue.map(el => formatDataLabel(el))

    const option = {
        tooltip: {
            textStyle: {
                fontFamily: 'Custom_font',               
                align: 'left' // Allinea il testo del tooltip a sinistra
            },
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                label: {show:false,
                    color: '#ffffff' // Colore del testo del tooltip
                }
            },
            axisLabel: {
                    fontFamily: 'Custom_font',  
                    formatter: formatDataLabel, // Usa la funzione di formattazione personalizzata
                    color: '#fff', // Colore delle etichette sull'asse x
                },        
        },
        toolbox: {
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        legend: {
            data: data && data.ylabel.map(el => el.charAt(0).toUpperCase() + el.slice(1).replaceAll('_',' ')),
            textStyle: {
                fontFamily: 'Custom_font',
                color: '#fff', // Dark red color for the legend text
            },
            itemStyle: {
                color: (color === 'red')?'#B30000':'#0096E6' // Color for the legend icon
            }
        },
        grid: {
            top: '20%',
            bottom: '5%',
            left: '0%',
            right: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: formatDataLabel(data.xvalue),
                axisPointer: {
                    type: 'shadow'
                },
                axisLabel: {
                    fontFamily: 'Custom_font',  
                    formatter: formatDataLabel, // Usa la funzione di formattazione personalizzata
                    color: '#fff', // Colore delle etichette sull'asse x
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff' // Colore della linea dell'asse x
                    }
                },
                axisTick: {
                    show: false // Nascondi i segni dell'asse x
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse x
                }
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: data.ylabel[0].charAt(0).toUpperCase() + data.ylabel[0].slice(1).replaceAll('_',' '),
                min: 0,
                max: maxY1Value,
                interval: maxY1Value / 5, // Imposta un intervallo dinamico
                axisLabel: {                    
                    fontFamily: 'Custom_font',
                    formatter: formatNumLabel, // Usa la funzione di formattazione personalizzata
                    color: '#fff' // Colore delle etichette dell'asse y
                },
                axisLine: {
                    lineStyle: {
                        color: '#fff' // Colore della linea dell'asse y 
                    }
                },
                axisTick: {
                    lineStyle: {
                        color: '#fff' // Colore dei segni dell'asse y 
                    }
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse y
                }
            },
            {
                type: 'value',
                name: data.ylabel[1].charAt(0).toUpperCase() + data.ylabel[1].slice(1).replaceAll('_',' '),
                min: 0,
                max: Math.max(...data.y2value),
                interval: Math.max(...data.y2value) / 5, // Imposta un intervallo dinamico
                axisLabel: {
                    formatter: formatNumLabel, // Usa la funzione di formattazione personalizzata
                    color: '#fff' // Colore delle etichette dell'asse y
                },
                
                axisLine: {
                    lineStyle: {
                        color: '#fff' // Colore della linea dell'asse y 
                    }
                },
                axisTick: {
                    lineStyle: {
                        color: '#fff' // Colore dei segni dell'asse y 
                    }
                },
                splitLine: {
                    show: false // Nascondi le righe di suddivisione dell'asse y
                }
            }
        ],
        series: [
            {
                name: data.ylabel[0].charAt(0).toUpperCase() + data.ylabel[0].slice(1).replaceAll('_',' '),
                type: 'bar',
                tooltip: {
                    textStyle: {
                        fontFamily: 'Custom_font',
                        align: 'left' // Allinea il testo del tooltip a sinistra
                    },
                    valueFormatter: function (value) {
                        return formatNumber(value);
                    }
                },
                itemStyle: {
                    color: (params) => getColorBasedOnIndex(params.value, maxY1Value, color)
                },
                data: data.y1value
            },
            {
                name: data.ylabel[1].charAt(0).toUpperCase() + data.ylabel[1].slice(1).replaceAll('_',' '),
                type: 'line',
                yAxisIndex: 1,
                tooltip: {
                    textStyle: {
                        align: 'left' // Allinea il testo del tooltip a sinistra
                    },
                    valueFormatter: function (value) {
                        return formatNumber(value);
                    }
                },
                lineStyle: {
                    color: '#17becf' // Color of the line
                },
                itemStyle: {
                    color: (color === 'red')?'#B30000':'#0096E6',
                    borderColor: '#fff', // Border color of the points
                    borderWidth: 2 // Thickness of the border
                },
                symbolSize: 10, // Size of the points (increase this value for larger points)
                data: data.y2value
            }
        ]
    };

    return <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />;
};

export default MixedChartComponent;
