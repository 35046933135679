import * as React from 'react';
import {createContext, useEffect, useReducer} from 'react';

// third-party
import {Auth0Client} from '@auth0/auth0-spa-js';

// reducer - state management
import {LOGIN, LOGOUT} from '../store/actions.ts';
import accountReducer from '../store/accountReducer.ts';

// types
import {Auth0ContextType, InitialLoginContextProps, KeyedObject} from '../../interfaces/auth';

// constant
let auth0Client: Auth0Client;

const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

export const Auth0Provider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                auth0Client = new Auth0Client({
                    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
                    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
                    authorizationParams: {
                        redirect_uri: window.location.origin,
                        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
                    }
                });
                await auth0Client.checkSession();
                const isLoggedIn = await auth0Client.isAuthenticated();
                if (isLoggedIn) {
                    const user = await auth0Client.getUser();
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user?.sub,
                                email: user?.email
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                throw new Error('Errore di certificato');
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (options?: KeyedObject) => {
            await auth0Client.loginWithPopup(options);
            
            const isLoggedIn = await auth0Client.isAuthenticated();
            if (isLoggedIn) {
                const user = await auth0Client.getUser();
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            email: user?.email,
                            name: user?.name,
                        }
                    }
                });
            }
    };

    const logout = () => {
        auth0Client.logout({
            clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
            //returnTo: window.location.origin + '/login'
        });

        dispatch({
            type: LOGOUT
        });
    };

    const getToken = async () => {
        try {
            return await auth0Client.getTokenSilently();
        } catch (error) {
            console.error('Error getting token:', error);
            throw error;
        }
    };

    const resetPassword = async (email: string) => {};

    const updateProfile = () => {};


    return (
        <Auth0Context.Provider value={{ ...state, login, logout, resetPassword, updateProfile, getToken }}>
            {children}
        </Auth0Context.Provider>
    );
};

export default Auth0Context;