import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export default function CustomSnackbar({open, setOpen, message, correct}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={handleClose}
        message={message}
        sx={{
          '& .MuiPaper-root': {
            color: 'white',
            border: (correct)? '2px solid green' : '2px solid red',
          }
        }}
        action={
          <IconButton 
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => handleClose()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
  );
}