import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import DashboardContainer from './DashboardContainer.tsx';
import { FilterProvider } from "../providers/FilterProvider.tsx";
import '../../style/home.css';
import '../../style/header.css';
import '../../style/footer.css';
import Footer from '../elements/Footer.tsx';
import Header from '../elements/Header.tsx';
import CustomSnackbar from '../utils/SnackBarCustomComponent.tsx';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function MainPageWrapper() {
    const [value, setValue] = React.useState(0);
    const [isError, setIsError] = React.useState({label: '', message: ''});
    const [open, setOpen] = React.useState(false);
    const entityList= [{label:'Overview', title: 'overview - 90 giorni', id: 'overview'},
        {label: 'Audience', title: 'audience', id: 'audience'},
        {label: 'Live', title: 'contenuti live', id: 'contenuti_live'},
        {label: 'On Demand', title: 'contenuti on demand', id: 'contenuti_on_demand'},
        {label: 'Musica', title: 'musica', id: 'music'},
        {label: 'Advertising', title: 'advertising', id: 'advertising'},
        {label: 'Piattaforme', title: 'piattaforme', id: 'platforms'},
        //{label: 'Social', title: 'social', id:'social'},
    ];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // Usa il breakpoint per determinare la larghezza dello schermo
    const refs = React.useRef<any>(entityList.map(() => React.createRef()));

    const handleChange = (event, newValue) => {
      setValue(newValue);
      // Scrolla all'elemento selezionato
      if(refs.current[newValue] && refs.current[newValue].current)
        refs.current[newValue].current.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" });      
    };

    React.useEffect(()=> {
      if(isError.label !== '')
        {setOpen(true)}     
    }, [isError])

    return (
      <FilterProvider>
        <Box id='myDiv' className='screen' >
        
          <Header entityList={entityList} value={value} handleChange={handleChange} a11yProps={a11yProps} refs={refs} isSmallScreen={isSmallScreen} />

          <CustomSnackbar open={open} setOpen={setOpen} message={isError.message} correct={false}/>

          {entityList && entityList.map((tabElement, index) => {
              return <TabPanel key={'TabPanel'+index} value={value} index={index}>
                <DashboardContainer indexDashboard={index} idDashboard={tabElement['id']} setIsError = {setIsError} isSmallScreen={isSmallScreen} />
              </TabPanel>
            }
          )}

         <Footer entityList={entityList} value={value} handleChange={handleChange} a11yProps={a11yProps} isSmallScreen={isSmallScreen} />
          
        </Box>
      </FilterProvider>
    );
}

export default MainPageWrapper;
