import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import PieChartComponent from '../graphs/PieChart/PieChartComponent.tsx';
import StackedBarChartComponent from "../graphs/BarChart/StackedBarChartComponent.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";

export const Contenuti_Live = ({ currentDashboardGraph, indexDashboard, idDashboard, isSmallScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 1: setDataGraph2({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 2: setDataGraph3({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 3: setDataGraph4({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 4: setDataGraph5({ title: currentDashboardGraph[index].title, data: responseData.data });break;
        }
    }

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, indexDashboard, [0,1,2,3,4], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
            >
                <Grid item xs={12}>{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<PieChartComponent data={dataGraph1.data} />} title={dataGraph1.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph2 && dataGraph2.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph2.data} color={'blue'} />} title={dataGraph2.title} />
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph3 && dataGraph3.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph3.data} />} title={dataGraph3.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                 <Grid item xs={12}>{(dataGraph4 && dataGraph4.data)
                    ?<MainCard element={<StackedBarChartComponent data={dataGraph4.data} />} title={dataGraph4.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                 <Grid item xs={12}>{(dataGraph5 && dataGraph5.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph5.data} />} title={dataGraph5.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid> 
        </Grid>
    )
}