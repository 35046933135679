import React from "react";
import { Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";
import useAuth from "../hooks/useAuth.ts";

export const Music = ({ currentDashboardGraph, indexDashboard, idDashboard, isSmallScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 1: setDataGraph2({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 2: setDataGraph3({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 3: setDataGraph4({ title: currentDashboardGraph[index].title, data: responseData.data });break;
        }
    }

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, indexDashboard, [0,1,2,3], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

           <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph1.data} />} title={dataGraph1.title} />
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph2 && dataGraph2.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph2.data} />} title={dataGraph2.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph3 && dataGraph3.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph3.data} color={'blue'}/>} title={dataGraph3.title} />
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph4 && dataGraph4.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph4.data} color={'blue'}/>} title={dataGraph4.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
        </Grid>     
    )
}