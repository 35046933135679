import React from 'react';
import axios from 'axios';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AutocompleteFilter from '../elements/AutocompleteFilter.tsx';
import { FilterContextEl, useFilter } from '../providers/FilterProvider.tsx';
import '../../style/home.css'
import dayjs from 'dayjs';
import { GraphData, FilterData } from '../elements/DashboardElement.tsx';
import Dashboard from '../elements/Dashboard.tsx';

const filter_style = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '15px',
      borderColor: 'gray', // Colore del bordo normale
    },
    '&:hover fieldset': {
      borderColor: 'White', // Colore del bordo al passaggio del mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: 'gray', // Colore del bordo quando è attivo
    },
    '& .MuiInputBase-input': {
        color: 'white', // Assicurati che il colore del testo all'interno dell'input sia bianco
        fontWeight: 'bold', // Grassetto quando è attivo
    }, 
    '&.Mui-focused:not(:focus-within) fieldset': {
      borderColor: 'gray', // Mantieni il bordo grigio quando selezionato ma senza focus
    },
  },
  '& .MuiInputLabel-root': {
      color: 'White', // Colore bianco quando è attivo
      fontWeight: 'bold', // Grassetto quando è attivo
  '&.Mui-focused': {
      color: 'White', // Colore bianco quando è attivo
      fontWeight: 'bold', // Grassetto quando è attivo     
  },
  },
}

const DashboardContainer = ({indexDashboard, idDashboard, setIsError, isSmallScreen}) => {
    const { addFilters, isAllSelected, getFilterValueList, setFilterValueList, getFilteredOptionList } = useFilter();
    const [filtersList, setFiltersList] = React.useState<FilterContextEl[]>([]);
    const [currentDashboardGraph, setCurrentDashboardGraph] = React.useState<FilterContextEl[]>([]);

    function callMutationData(type='update') {       
      const currentDashboard = GraphData[idDashboard.toLowerCase()]
      if(currentDashboard) { 
        setCurrentDashboardGraph([]);
        setIsError({label: '', message: ''})
        if(idDashboard.toLowerCase() !== 'overview'){
          currentDashboard.forEach(graphItem => {
            graphItem.payload['start_date'] = (type==='update')?getFilterValueList('startDate').format('YYYY-MM-DD'):dayjs().subtract(1, 'week').format('YYYY-MM-DD');
            graphItem.payload['end_date'] = (type==='update')?getFilterValueList('endDate').format('YYYY-MM-DD'):dayjs().format('YYYY-MM-DD');
            
            /*graphItem.payload['start_date'] = (type==='update')?getFilterValueList('startDate').format('YYYY-MM-DD HH:mm'):dayjs().subtract(1, 'week').format('YYYY-MM-DD HH:mm');
            graph Item.payload['end_date'] = (type==='update')?getFilterValueList('endDate').format('YYYY-MM-DD HH:mm'):dayjs().format('YYYY-MM-DD HH:mm');*/
            
            filtersList.forEach((selectedFilter) => {
              const newSelected = (type==='update')?getFilterValueList(selectedFilter.name) : []
              graphItem.payload[selectedFilter.name] = (newSelected)? newSelected.map(el => el.id) : []
            });
          });
        }
        setCurrentDashboardGraph([...currentDashboard])       
      } 
      else setIsError({ label: 'data', message: `Data for one or more charts not available` });
    };  // Dependency array to ensure `callMutationData` is stable

    const fetchAudienceFilters = () => {
      return axios.post(process.env.REACT_APP_BACKEND_URL+'filters', FilterData[idDashboard] );
    };

    const getFilters = () => {
      return new Promise((resolve, reject) => {
        fetchAudienceFilters()
          .then((response) => {
            resolve({ data: response.data });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    function callMutationEntityFilters() {
      getFilters()
        .then((response: any) => {
          const filtersData: FilterContextEl[] = response.data;
          setFiltersList(addFilters(filtersData));
        })
        .catch((error) => {
          //setIsError({ label: 'Options', message: `Options for ${nameDashboard} dashboard not available` });
        });
    }

    const handlerReset = () => {
      setFilterValueList('startDate', dayjs().subtract(1, 'week'), dayjs().subtract(1, 'week'))
      setFilterValueList('endDate', dayjs(), dayjs())
      filtersList.forEach((selectedFilter) => {
        setFilterValueList(selectedFilter.id, [], null)
      });
      callMutationData('reset')
    };

    const handlerUpdate = () => {
      callMutationData();
    };

    React.useEffect(() => {
      if((filtersList || indexDashboard === 0) && currentDashboardGraph.length === 0)
        callMutationData();
    }, [filtersList]);


    React.useEffect(() => {
      console.log('useeffect', currentDashboardGraph)
    }, [currentDashboardGraph]);


    React.useEffect(() => {      
      if(indexDashboard > 0) {
        const proxyFilters = FilterData[idDashboard]["columns"].map((el) => {return {"name":"","id":"","impacted":[],"filtered": null,"optionList":[],"type":"multiselector","isAllSelected": false}})
        setFiltersList(proxyFilters)
        callMutationEntityFilters();
      }      
    }, []);

    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
        divider: '#fff',
        text:{
          primary: '#fff',
          secondary: '#fff', 
          disabled: '#000', 
        },
        
        background:{
          default: '#000',
          paper: '#000',
        }
      },
    });

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            {indexDashboard > 0 && filtersList.length>0 && 
              <Grid container spacing={{ xs: 2, md: 3 }}>    
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <ThemeProvider theme={darkTheme}>

                    <Grid key={'start-date'} item xs={12} md={6} lg={12/(filtersList.length+3)} display="flex" justifyContent="center" alignItems="center">
                      <DateTimePicker
                        label="Data di Inizio"
                        value={getFilterValueList('startDate')}
                        onChange={(newValue) => {
                          setFilterValueList('startDate', newValue, newValue)
                        }}                        
                        sx={filter_style}
                        className="custom-filter"
                        minDate={dayjs().subtract(3, 'month')}
                        maxDate={getFilterValueList('endDate')}
                        format="DD/MM/YYYY HH:mm"  // Formato per mostrare anche l'ora
                        ampm={false}
                        minutesStep={1}timeSteps={{ minutes: 1 }}
                        slots={{
                          actionBar: () => null,       // Rimuove la barra dei pulsanti (OK e Cancel)
                        }}
                      />
                    </Grid>

                    <Grid key={'end-date'} item xs={12} md={6} lg={12/(filtersList.length+3)} display="flex" justifyContent="center" alignItems="center">
                      <DateTimePicker
                        label="Data di Fine"
                        value={getFilterValueList('endDate')}
                        onChange={(newValue) => {
                          setFilterValueList('endDate', newValue, newValue);
                        }}
                        sx={filter_style}
                        className="custom-filter"
                        minDate={getFilterValueList('startDate')}
                        maxDate={dayjs()}
                        format="DD/MM/YYYY HH:mm"  // Formato per mostrare anche l'ora
                        ampm={false}
                        minutesStep={1}timeSteps={{ minutes: 1 }}
                        slots={{
                          actionBar: () => null,       // Rimuove la barra dei pulsanti (OK e Cancel)
                        }}
                      />
                    </Grid> 
                    
                    {filtersList.map((filter, index) => (
                      <Grid key={'filter-'+index} item xs={12} md={6} lg={12/(filtersList.length+3)} display="flex" justifyContent="center" alignItems="center">
                        {<AutocompleteFilter key={'filter-AutocompleteFilter-'+idDashboard+'-'+index} 
                          showSelected={idDashboard !== 'contenuti_on_demand'}
                          filter_style={filter_style}
                          allSelected={isAllSelected(filter.name)}
                          value={getFilterValueList(filter.name)}
                          options={getFilteredOptionList(filter, (filter.filtered)? getFilterValueList(filter.filtered[0]) || []:[])}
                          caption={FilterData[idDashboard].columns_alias[index]}
                          name={filter.name}
                          setFilterValueList={setFilterValueList} />}
                      </Grid>
                    ))}

                    <Grid key={'button-filter'} item container xs={12} md={6} lg={12/(filtersList.length+3)}
                      direction="row" sx={{justifyContent: "center", alignItems: "center", columnGap:'10px'}}>
                        <Grid item ><button className={(idDashboard === 'contenuti_on_demand')?"button-query-small":"button-query"} onClick={handlerReset}>Ripristina</button></Grid>
                        <Grid item ><button className={(idDashboard === 'contenuti_on_demand')?"button-query-small":"button-query"} onClick={handlerUpdate}>Aggiorna</button></Grid>            
                    </Grid>
                  </ThemeProvider>
                </LocalizationProvider>
              </Grid>
            }

            <Dashboard currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>
        </Grid>
    );
};

export default DashboardContainer;