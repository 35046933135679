export const generateColorPalette = (data, color) => {
  const startColor  = (color === 'red') ? [255, 230, 230] : [229, 204, 255];
  const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255];
  const singleColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255];

  const maxValue = Math.max(...data); // Trova il valore massimo in 'data'

  if (data.length > 1) {
    const colorPalette = data.map((item) => {
      const ratio = item / maxValue; // Calcola il rapporto rispetto al valore massimo
      const color = startColor.map((start, i) => {
        let newColor = Math.round(start + ratio * (endColor[i] - start));
        return (newColor >= endColor[i]) ? newColor : singleColor[i]; // Usa il fallback se necessario
      });
      return rgbToHex(color); // Converte l'RGB in esadecimale
    });
    return colorPalette;
  } 
  return [rgbToHex(endColor)]; // Ritorna il colore finale come palette
};

// Funzione per calcolare la media dei dati
export const calculateAverage = (data) => {
    const total = data.reduce((acc, value) => acc + value, 0);
    return total / data.length;
};

// Funzione per calcolare il colore in base all'indice di media
export const getColorBasedOnIndex = (index, totalIndices, color) => {
    const startColor  = (color === 'red') ? [255, 230, 230] : [226, 235, 255];
    const endColor = (color === 'red') ? [255, 0, 0] : [0, 128, 255];

    const scale = index / totalIndices;
    const newColor = startColor.map((c, i) => Math.round(c + scale * (endColor[i] - c)));

    return `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
};

// Funzione di supporto per convertire RGB in esadecimale
const rgbToHex = (rgb) => {
  return `#${rgb.map(c => c.toString(16).padStart(2, '0')).join('')}`;
};

export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat('it-IT').format(num);
};

// Funzione per formattare i label dell'asse
export const formatDataLabel = (value) => {
    if (typeof value === 'string') {
        return value.replace("T00:00:00", '');
    }
    return value;
};

export const formatNumLabel = (value) => {
    if (value >= 1000) {
        return `${(value / 1000).toFixed(0)} K`; // Mostra "x K" per valori >= 1000
    }
    return value; // Mostra il valore normale per valori < 1000
};

export const formatLegend = (value) => {
    if(value.length > 20) return value.substring(0, 17) + '...'
    return value
}

export const formatGrouppedLegend = (value) => {
    if(value.length > 40) return value.substring(0, 27) + '...'
    return value
}