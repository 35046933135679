import React from "react";
import { colors, Grid } from "@mui/material";
import MainCard from "../elements/MainCard.tsx";
import ScoreCardComponent from "../elements/ScoreCardComponent.tsx";
import PieChartComponent from '../graphs/PieChart/PieChartComponent.tsx';
import HeatmapChartComponent from "../graphs/HeatMap/HeatMapTableComponent.tsx";
import BarChartWithLabelsComponent from "../graphs/BarChart/BarChartWithLabelsComponent.tsx";
import SkeletonComponent from "../utils/Skeleton.tsx";
import "../../style/dashboard.css";
import ScatterPlotComponent from "../graphs/ScatterPlot/ScatterPlotComponent.tsx";
import useAuth from "../hooks/useAuth.ts";
import { fecthDataGraph} from "../utils/promiseUtils.tsx";

export const Overview = ({ currentDashboardGraph, indexDashboard, idDashboard, isSmallScreen }) => {
    const [dataGraph1, setDataGraph1] = React.useState<any>(null)
    const [dataGraph2, setDataGraph2] = React.useState<any>(null)
    const [dataGraph3, setDataGraph3] = React.useState<any>(null)
    const [dataGraph4, setDataGraph4] = React.useState<any>(null)
    const [dataGraph5, setDataGraph5] = React.useState<any>(null)
    const [dataGraph6, setDataGraph6] = React.useState<any>(null)
    const [dataGraph7, setDataGraph7] = React.useState<any>(null)
    const [dataGraph8, setDataGraph8] = React.useState<any>(null)
    const [dataGraph9, setDataGraph9] = React.useState<any>(null)
    const {getToken} = useAuth();

    const setDataGraph = ( index, responseData ) => {
        switch(index){
            case 0: setDataGraph1({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 1: setDataGraph2({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 2: setDataGraph3({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 3: setDataGraph4({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 4: setDataGraph5({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 5: setDataGraph6({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 6: setDataGraph7({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 7: setDataGraph8({ title: currentDashboardGraph[index].title, data: responseData.data });break;
            case 8: setDataGraph9({ title: currentDashboardGraph[index].title, data: responseData.data });break;
        }
    }

    React.useEffect(() => {
        fecthDataGraph(currentDashboardGraph, getToken, idDashboard, indexDashboard, [0,1,2,3,4,5,6,7,8], setDataGraph);
    }, [currentDashboardGraph]);

    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            gap='20px'>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} lg={2.95}>{(dataGraph1 && dataGraph1.data)
                    ?<MainCard element={<ScoreCardComponent data={dataGraph1.data} />} title={'HIGHLIGHTS'} />
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} lg={8.95}>{(dataGraph2 && dataGraph2.data)
                    ?<MainCard element={<PieChartComponent data={dataGraph2.data} sizeGraph={'small'} />} title={dataGraph2.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item xs={12}><div className="overview-tab-section">{'OVERVIEW CONTENUTI - 90 GIORNI'}</div></Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph3 && dataGraph3.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph3.data} color={'blue'}/>} title={dataGraph3.title} />
                    :<SkeletonComponent/>}
                </Grid>
                <Grid item xs={12} md={12} lg={5.95}>{(dataGraph4 && dataGraph4.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph4.data} />} title={dataGraph4.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph5 && dataGraph5.data)
                    ?<MainCard element={<BarChartWithLabelsComponent data={dataGraph5.data} color={'blue'}/>} title={dataGraph5.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}><div className="ter-tab-section"><span style={{color:'black'}}>{'>'}</span><span>{'DATI TER'}</span></div></Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph6 && dataGraph6.data) ? <MainCard element={<BarChartWithLabelsComponent data={dataGraph6.data} color={'neutral'} sizeGraph={'small'} sort={false}/>} title={dataGraph6.title} />:<SkeletonComponent/>}</Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph7 && dataGraph7.data) ? <MainCard element={<ScatterPlotComponent data={dataGraph7.data}/>} title={dataGraph7.title} />:<SkeletonComponent/>}</Grid>
                    <Grid item xs={12} md={12} lg={3.95}>{(dataGraph8 && dataGraph8.data) ? <MainCard element={<BarChartWithLabelsComponent data={dataGraph8.data} color={'neutral'} sizeGraph={'small'} sort={false}/>} title={dataGraph8.title} />:<SkeletonComponent/>}</Grid>
            </Grid>

            <Grid item
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowGap={'20px'}
                >
                <Grid item xs={12}>{(dataGraph9 && dataGraph9.data)
                    ?<MainCard element={<HeatmapChartComponent data={dataGraph9.data} isSmallScreen={isSmallScreen} />} title={dataGraph9.title} />
                    :<SkeletonComponent/>}
                </Grid>
            </Grid>
        </Grid>
    )
}