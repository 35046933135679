import React from 'react';
import { Grid } from "@mui/material";
import { formatNumber } from '../utils/graphUtils.tsx';

// Define a TypeScript interface for the data
interface DataItem {
  'Web Impressions': number;
  '% Utenti Loggati': number;
  '#Users': number;
  '#Touchpoints': number;
}

// Define the type for props
interface ScoreCardComponentProps {
  data: DataItem[];
}

// DataDisplay Component to render a list of data
const ScoreCardComponent: React.FC<ScoreCardComponentProps> = ({ data }) => {
  return (
    <Grid 
      container
      direction="column"
      sx={{
        justifyContent: "space-evenly",
        alignItems: "flex-start",
      }}
      style={{ height: '400px', width: '100%' }}
    >
      {data && data.map((item, index) => (   
        <React.Fragment key={"fragment_"+index}>   
          {Object.entries(item).map(([key, value], idx) => (
            <Grid item key={'score-card_'+ idx} style={{ marginBottom: '10px', textAlign: 'left' }}>
              <div key={idx}>
                <h3 className={'title-card'}>{key}</h3>
                <p className={'text-card'}>{(key.includes('%'))?(formatNumber(value*100)+'').substring(0, 6)+'%':formatNumber(value)}</p>
              </div>
            </Grid>
          ))}
        </React.Fragment>  
      ))}
    </Grid>
  );
};

export default ScoreCardComponent;
