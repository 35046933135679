import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import { Grid, MenuItem, Select, Tab, Tabs } from "@mui/material";
import '../../style/graph.css';
import { useNavigate } from 'react-router-dom';
import useAuth from "../hooks/useAuth.ts";

export default function Header ({entityList, value, handleChange, a11yProps, refs, isSmallScreen}) {
    let navigate = useNavigate()
    const { logout } = useAuth();

    const handleLogout = async () => {
        // Implement your logout logic here
        logout();
        //navigate('/login')
    };

    return (
        <Grid className='header'
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
        >
            <Grid item xs={2} className='rtl-logo-container' sx={{alignItems:'left'}}>
                <img src={`${process.env.PUBLIC_URL}/images/RTL_logo.svg`} style={{ width: '80%', height: 'auto' }}/>
            </Grid>

            <Grid container item xs={10}>
            <Grid
                container item
                direction="column"
                justifyContent="center"
                alignItems="stretch"
            >
            <Grid container item className='header-container'>
                <Grid item xs={8} md={9} className='title-container' >
                    <span className='title'>{entityList && `${entityList[value]['title'].toUpperCase()}`}</span>
                </Grid>

                <Grid item xs={2} md={3} className='logo-container' >
                    <img src={`${process.env.PUBLIC_URL}/images/kebula_logo_full_white_r.svg`} style={{ width: '60%', height: 'auto' }}/>
                </Grid>                            

            </Grid>
            
            <Grid container item >
                <Grid container item xs={11} justifyContent='flex-end' alignItems='flex-end'>
                 {isSmallScreen ? (
                    // Mostra menu a tendina quando lo schermo è piccolo
                    <Select
                        value={value}
                        onChange={(event) => handleChange(event, event.target.value)}
                        className='select'
                        
                        MenuProps={{
                            PaperProps: {
                            sx: {
                                color: 'white', // Colore del testo del menu
                                backgroundColor: 'var(--background-filter)',
                            },
                            },
                        }}
                    >
                        {entityList.map((tabElement, index) => (
                            <MenuItem key={'MenuItem' + index} value={index}>
                                {tabElement.label}
                            </MenuItem>
                        ))}
                    </Select>
                    ) : (
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                        TabIndicatorProps={{
                        style: {
                            overflowY: 'hidden',
                            backgroundColor: 'red', // Colore della barra indicatrice
                        },
                        }}
                        >
                        {entityList && entityList.map((tabElement, index) => {
                            return <Tab key={'Tab'+index} ref={refs.current[index]}
                                label={tabElement['label']} {...a11yProps({index})} 
                                sx={{ textTransform: 'none', fontFamily: 'Custom_font',
                                color: 'white', // Colore del testo delle schede
                                '&.Mui-selected': { color: 'white' }, // Colore del testo quando la scheda è selezionata
                                }}
                                className='tab-title'/>
                        }
                        )}
                    </Tabs>
                )}
                </Grid>  
                <Grid item xs={1} container direction="row" justifyContent='center' alignItems='center'>
                <LogoutIcon className="logout-button" onClick={handleLogout}/>
                </Grid>
            </Grid>

            </Grid>
        </Grid>
        </Grid>
    )
}