import React from 'react';
import { Overview } from '../dashboards/Overview.tsx';
import { Audience } from '../dashboards/Audience.tsx';
import { Contenuti_Live } from '../dashboards/Contenuti_Live.tsx';
import { Contenuti_On_Demand } from '../dashboards/Contenuti_On_Demand.tsx';
import { Music } from '../dashboards/Music.tsx';
import { Advertising } from '../dashboards/Advertising.tsx';
import { Platforms } from '../dashboards/Platforms.tsx';
import { Social } from '../dashboards/Social.tsx';
import '../../style/home.css'

const Dashboard = ({ currentDashboardGraph, indexDashboard, idDashboard, isSmallScreen }) => {

    switch(idDashboard.toLowerCase()){
        case 'overview': return <Overview currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'audience': return <Audience currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'contenuti_live': return <Contenuti_Live currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'contenuti_on_demand': return <Contenuti_On_Demand currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'music': return <Music currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'advertising': return <Advertising currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'platforms': return <Platforms currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;
        case 'social': return <Social currentDashboardGraph={currentDashboardGraph} indexDashboard={indexDashboard} idDashboard={idDashboard} isSmallScreen={isSmallScreen}/>;;
    }
}

export default React.memo(Dashboard);